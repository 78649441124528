import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import MiniGameComponent from './MiniGameComponent';
import { faArrowCircleLeft, faFilter, faGamepad, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { getRecords, getRecordsPublic } from '../../api/userFunctions';
import config from '../../config/config';

const GamesComponent = ({id, dynamic_id}) => {
    const [games, setGames] = useState([]);
    
    const [filteredGames, setFilteredGames] = useState(games);
    const [typeFilter, setTypeFilter] = useState('All');
    const [categoryFilter, setCategoryFilter] = useState('All');
    const [selectedGameId, setSelectedGameId] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          let records;
          if (config.apiUrl === "http://localhost:8080") {
            records = await getRecords('MiniGame');
            setGames(records.data);
          } else {
            records = await getRecordsPublic('MiniGame', id, dynamic_id);
            setGames(records);
          }
        };
        fetchData();
      }, []);

    useEffect(() => {
        const filtered = games.filter(game => 
            (typeFilter === 'All' || game.type === typeFilter) &&
            (categoryFilter === 'All' || game.category === categoryFilter)
        );
        setFilteredGames(filtered);
    }, [typeFilter, categoryFilter, games]);

    const types = ['All', ...new Set(games.map(game => game.type))];
    const categories = ['All', ...new Set(games.map(game => game.category))];

    const handleGameClick = (gameId) => {
        setSelectedGameId(gameId);
    };

    return (<>
        {!selectedGameId && <div className="p-4 pt-0">
            <div className='flex flex-col items-center justify-center'>
                <h1 className="text-2xl font-bold mb-3 text-pacifico text-yellow-400"><FontAwesomeIcon icon={faGamepad} className='mr-2 text-blue-500' />Andy's Games</h1>
                {showFilters ? (<>
                    <div className="flex flex-col gap-1 items-center justify-center w-full">
                    <div className=''>
                        Filter by type:
                    </div>
                    <div className="mb-2 w-full">
                        <select
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                            className="px-4 py-2 rounded border border-gray-900 bg-yellow-400 text-black w-full"
                        >
                            {types.map(type => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex flex-col gap-1 items-center justify-center w-full">
                    <div className=''>
                        Filter by category:
                    </div>
                    <div className="mb-4 w-full">
                        <select
                            value={categoryFilter}
                            onChange={(e) => setCategoryFilter(e.target.value)}
                            className="px-4 py-2 rounded border border-gray-900 bg-yellow-400 text-black w-full"
                        >
                            {categories.map(category => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <button onClick={() => setShowFilters(false)} className="mb-4">
                        <FontAwesomeIcon icon={faXmarkCircle} className='text-4xl text-yellow-400 hover:text-blue-500' />
                    </button>
                </>) : (
                    <button onClick={() => setShowFilters(true)} className="rounded px-4 mb-4 border border-yellow-400">
                        <FontAwesomeIcon icon={faFilter} className='text-yellow-400' />
                    </button>
                
                )}
                
            </div>
            <div className="grid grid-cols-2 gap-4">
                {filteredGames.map((game) => (
                    <div
                        key={game._id}
                        className="border border-blue-500 p-4 rounded-lg shadow-lg flex flex-col items-center cursor-pointer"
                        onClick={() => handleGameClick(game._id)}
                    >
                        <img src={game.image} alt={game.name} className="w-full h-48 object-cover mb-4 rounded-md"/>
                        <h2 className="text-2xl font-semibold text-blue-500 text-center">{game.name}</h2>
                        <p className="mb-4 text-gray-500">{game.description}</p>
                        <div className="mt-auto flex items-center">
                            <FontAwesomeIcon icon={faCirclePlay} className='mr-2 text-lg' />
                            <span>Play Now</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>}
        {selectedGameId && (<>
            <div className='text-yellow-400'>
                <button onClick={() => setSelectedGameId(null)} className='text-yellow-400 hover:text-blue-500 text-3xl focus:outline-none'>
                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                </button>
            </div>
            <MiniGameComponent id={selectedGameId} games={games} />
        </>)}
    </>);
}

export default GamesComponent;