const config = {
    siteTitle: "Andy's Place | ANDY on Base",
    siteName: "Andy's Place",
    siteDescription: "Explore Andy's Place, your ultimate destination for all things ANDY memecoin! Dive into a world of exclusive merch, interactive AI characters, and the latest updates on ANDY token. Join our vibrant community and stay ahead in the crypto game with Andy's Place. Start your adventure today!",
    siteKeywords: "ANDY memecoin, Andy's Place, crypto token, cryptocurrency, blockchain, AI characters, crypto merch, memecoin community, digital currency, crypto investment, Andy token, blockchain technology",
    path: "andyonbase",
    logoUrl: "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/andyOnBaseSunglassesLogo.png",
    favicon: "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/andyAvatar300.jpg",
    shopUrl: "https://shop.andys.place",
    app_id: "661852defbc4c47034c9f046",
    app_url: "https://andys.place",
    twitter: "@AndysPlaceBase",
    mailerLiteGroupID: '120412081337927037',
    
    /*apiUrl: "http://localhost:8080",
    stytch_login_url: "http://localhost:3000/loggingin",
    stytch_public_token: "public-token-test-87fd74cf-f520-4946-a3cd-32f67dde94aa",*/

    apiPublicUrl: "https://andy-public-api-p7ccf.ondigitalocean.app",

    stytch_login_url: "https://app.andys.place/loggingin",
    apiUrl: "https://andy-back-end-loi4p.ondigitalocean.app",
    stytch_public_token: "public-token-live-b7c04a5d-12b5-4e2a-a4ad-c93e4d0a1953",
  };
  
  export default config;