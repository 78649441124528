// src/components/Modal.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from '../features/ui/uiSlice';

const Modal = () => {
  const { isVisible, content } = useSelector((state) => state.ui.modal);
  const dispatch = useDispatch();

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-blue-500 p-4 rounded text-white flex flex-col w-full md:w-1/3">
        {content}
        <button className='bg-yellow-400 text-blue-500 hover:bg-gray-400 rounded p-2 mt-10 ' onClick={() => dispatch(hideModal())}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
