import React, { useState, useEffect } from 'react';
import { getRecords, getRecordsPublic } from '../../api/userFunctions';
import config from '../../config/config';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getRandomItems = (items, maxItems) => {
  const shuffled = items.slice().sort(() => 0.5 - Math.random());
  return shuffled.slice(0, maxItems);
};

const MerchComponent = ({id, dynamic_id}) => {
  const [allMerchItems, setAllMerchItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let records;
      if (config.apiUrl === "http://localhost:8080") {
        records = await getRecords('CryptoMerchItems');
        setAllMerchItems(records.data);
      } else {
        records = await getRecordsPublic('CryptoMerchItems', id, dynamic_id);
        setAllMerchItems(records);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const newItems = getRandomItems(allMerchItems, 10);
    setDisplayItems(newItems);
  }, [allMerchItems]);

  const openProductPage = (productName) => {
    window.open(`https://shop.andys.place/products/${productName}`, '_blank');
  };

  if (!displayItems.length) return <span>Loading...</span>;

  return (
    <div className="w-full slim-scrollbar merch-display" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
      <div className="flex flex-row justify-center items-center text-yellow-400 text-2xl font-bold text-pacifico mb-2">
        <FontAwesomeIcon icon={faStore} className="mr-2 text-primary text-2xl" /><br></br>
        Andy Merch
      </div>
      <div className='text-center mb-6 px-6'>Show your ANDY pride with some amazing merch. Shop for sweet T-shirts, Hoodies, Mugs, and much more!</div>
      <div className="grid grid-cols-2 gap-6">
        {displayItems?.map((item, index) => (
          <div key={index} className="mb-6 flex flex-col items-center text-center">
            {/* Image container */}
            <img
              src={item.image_url}
              alt={`Product ${item.name}`}
              className="w-40 h-40 object-cover mb-2 cursor-pointer border-2 border-primary rounded"
              onClick={() => openProductPage(item.product_name)}
            />
            <span
              className="text-white hover:text-yellow-400 text-center cursor-pointer"
              onClick={() => openProductPage(item.product_name)}
            >
              {item.name}
            </span>
            <button
              onClick={() => openProductPage(item.product_name)}
              className="mt-2 bg-primary text-inverted py-2 px-4 rounded-lg font-semibold cursor-pointer hover:bg-opacity-90"
            >
              View Item
            </button>
          </div>
        ))}
      </div>
      <div className='flex justify-center items-center gap-5 my-10'>
        <img
          src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
          alt="Avatar"
          className={`w-16 h-12`}
        />
        <button
          onClick={() => window.open('https://shop.andys.place', '_blank')}
          className="mt-2 bg-primary text-inverted py-2 px-4 rounded-lg font-semibold cursor-pointer hover:bg-opacity-90"
        >
          Visit Andy's Merch Shop
        </button>
      </div>
    </div>
  );
};

export default MerchComponent;