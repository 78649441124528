import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const HeaderComponent = ({ currentTool, isTalking, toggleDescriptionExpand, showDescription, toggleMenuExpand, showMenu }) => (
  <div className="flex items-center justify-between p-3 bg-gray-800" style={{ height: '8vh' }}>
    <div className="flex items-center space-x-2">
      <img src={currentTool?.image_url} alt="Avatar" className={`border-2 border-yellow-400 rounded-full w-10 h-10 ${isTalking ? 'talking' : ''}`} />
      <div className="text-xl font-bold text-pacifico text-yellow-400">
        {currentTool?.name}
      </div>
    </div>
    <div className='flex flex-row gap-5 items-center mr-3'>
        <button className="text-yellow-400 hover:text-blue-500 text-3xl focus:outline-none" onClick={toggleDescriptionExpand}>
        <FontAwesomeIcon icon={!showDescription ? faInfoCircle : faChevronUp} />
        </button>
        <button className="text-yellow-400 hover:text-blue-500 text-3xl focus:outline-none" onClick={toggleMenuExpand}>
        <FontAwesomeIcon icon={!showMenu ? faBars : faChevronUp} />
        </button>
    </div>
  </div>
);

export default HeaderComponent;
