import React, { useEffect, useState } from 'react';
import { getBlogPosts } from '../api/userFunctions';
import { useDispatch, useSelector } from 'react-redux';
import BlogList from '../components/blog/BlogList';
import Search from '../components/blog/Search';
import CategoryFilter from '../components/blog/CategoryFilter';
import Pagination from '../components/blog/Pagination';
import config from '../config/config';
import '../index.css';
//import '../components/blog/blog.css';
import FunBanner from '../components/FunBanner';
import { Helmet } from 'react-helmet';

const Home = () => {
  const postsFromRedux = useSelector((state) => state.blogs.items);
  const [posts, setPosts] = useState(postsFromRedux.blogPosts);
  //const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false); // Set to true for embedded blog***
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  /*useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getBlogPosts();
        if (response.blogPosts && Array.isArray(response.blogPosts)) {
          setPosts(response.blogPosts); // Use the blogPosts array from the response
        } else {
          console.error('Expected an array of posts in response.blogPosts');
        }
      } catch (error) {
        console.error('Error setting posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);*/

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedCategory === '' || post.category === selectedCategory)
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <div>
      <Helmet>
            <title>Andy's Based Blog - Crypto, Memecoins, and More!</title>
            <meta name="description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
            <meta name="keywords" content="ANDY memecoin, Andy's Place, crypto token, cryptocurrency, blockchain, AI characters, crypto merch, memecoin community, digital currency, crypto investment, Andy token, blockchain technology" />
            <link rel="icon" type="image/png" href="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
            <link rel="canonical" href={`${config.app_url}/blog`} />
            <meta property="og:title" content="Andy's Based Blog - Crypto, Memecoins, and More!" />
            <meta property="og:description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
            <meta property="og:image" content="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
            <meta property="og:url" content={`${config.app_url}/blog`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Andy's Based Blog" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Andy's Based Blog - Crypto, Memecoins, and More!"/>
            <meta name="twitter:description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
            <meta name="twitter:image" content="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
            <meta name="twitter:site" content={config.twitter} />
            <meta name="twitter:creator" content={config.twitter} />
          </Helmet>
      <div className='lg:-mt-5'><FunBanner size={'1000x200'} /></div>
      <div className="container mx-auto p-4">
        <div className='flex flex-row gap-3 items-center text-5xl uppercase mb-2'>
          <img
                    src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg"
                    alt="Avatar"
                    className={`w-16 h-auto rounded-full`}
                  /> 
            <div className='neon-flicker'>Andy's Based Blog</div>
        </div>
        <Search onSearch={setSearchQuery} />
        <CategoryFilter
          categories={[...new Set(posts.map(post => post.category))]}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
        {loading ? <p>Loading...</p> : <BlogList posts={currentPosts} />}
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredPosts.length / postsPerPage)}
          onPageChange={setCurrentPage}
        />
      </div>
      <div className='pt-16'>
            <FunBanner size={'1920x1080'} />
            </div>
    </div>
  );
};

export default Home;
