import React, { useEffect, useRef, useState } from 'react';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { shuffle } from 'lodash';
import { getRecords, getRecordsPublic } from '../../api/userFunctions';
import config from '../../config/config';

const ImagesComponent = ({id, dynamic_id}) => {
  const galleryRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let records;
      if (config.apiUrl === "http://localhost:8080") {
        records = await getRecords('ShillImage');
        setImagesArray(records.data);
      } else {
        records = await getRecordsPublic('ShillImage', id, dynamic_id);
        setImagesArray(records);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (galleryRef.current) {
      setCurrentImage(images[0]?.original);
    }
  }, [galleryRef.current]);

  useEffect(() => {
    const imagesShuffled = shuffle(imagesArray).map(image => ({
        original: image.url
      }));
    setImages(imagesShuffled);
    }, [imagesArray]);
  

  const handleSlide = (index) => {
    setCurrentImage(images[index]?.original);
  };

  const handleDownload = async () => {
    if (!currentImage) return;

    const response = await fetch(currentImage);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'image.jpg');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className="flex flex-col p-3 bg-black-main text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out" style={{ height: '59vh' }}>
      <div className='mx-auto text-pacifico text-2xl mb-4'>Andy Images</div>
      <div className='text-center mb-4'>Browse through some awesome Andy memes and images to steal and shill!</div>
      <div ref={galleryRef} className="flex flex-col w-2/3 rounded mx-auto">
        <ImageGallery items={images} onSlide={handleSlide} />
      </div>
      <button onClick={handleDownload} className="mt-4 mx-auto bg-yellow-400 text-black font-bold p-2 rounded hover:text-white hover:bg-blue-700 focus:outline-none">
        Save Image
      </button>
    </div>
  );
};

export default ImagesComponent;
