import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import FunBanner from '../components/FunBanner';

function MiniGame() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const games = useSelector((state) => state.miniGames.games);
    const [gameId, setGameId] = useState(null);
    const [gameData, setGameData] = useState(null);

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id) {
            navigate('/fun');
        } else {
            setGameId(id);
            // Fetch game data based on the 'id' query parameter
            const game = games.find(game => game._id === id);
            if (game) {
                setGameData(game);
            } else {
                navigate('/fun');
            }
        }
    }, [searchParams, navigate]);

    return (
        <div>
            <div className="flex flex-col items-center justify-center md:-mt-6">
                <h1 className="text-xl md:text-2xl lg:text-3xl font-bold lg:mb-4 text-pacifico text-blue-500">{gameData?.name}</h1>
                <span className="lg:hidden mb-3 text-xs lg:text-base text-gray-500 ml-2">Scroll down to see all puzzle pieces.</span>
            </div>
            {/*<div className="flex flex-row justify-center mb-4">
                <button onClick={() => navigate('/fun')} className="bg-primary text-black rounded py-2 cursor-pointer text-center flex flex-row items-center justify-center xl:justify-center space-x-4 w-1/2 xl:w-1/5">
                    <FontAwesomeIcon icon={faArrowCircleLeft} className='mr-2' />Back to Fun
                </button>
    </div>*/}
            

            <iframe className='mx-auto w-full xl:w-2/3 h-screen' style={{border: "none", borderRadius: '10px'}} src={gameData?.url}></iframe>

            <div className="flex flex-row justify-center mt-8">
                <button onClick={() => navigate('/fun')} className="bg-primary text-black rounded py-2 cursor-pointer text-center flex flex-row items-center justify-center xl:justify-center space-x-4 w-1/2 xl:w-1/3">
                    <FontAwesomeIcon icon={faArrowCircleLeft} className='mr-2' />Back to Fun
                </button>
            </div>
            <div className='pt-16'>
                <FunBanner size={'1920x1080'} />
            </div>
        </div>
    );
}

export default MiniGame;
