import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
//import FunBanner from '../../components/FunBanner';

const MiniGameComponent = ({id, games}) => {
    const [gameData, setGameData] = useState(null);

    useEffect(() => {
        const game = games.find(game => game._id === id);
        if (game) {
            setGameData(game);
        }
    }, [id, games]);

    return (
        <div>
            <div className="flex flex-col items-center justify-center">
                <h1 className="text-xl font-bold mb-2 text-pacifico text-yellow-400">{gameData?.name}</h1>
                {/*<span className="lg:hidden mb-3 text-xs lg:text-base text-gray-500 ml-2">Scroll down to see all puzzle pieces.</span>*/}
            </div>
            {/*<div className="flex flex-row justify-center mb-4">
                <button onClick={() => navigate('/fun')} className="bg-primary text-black rounded py-2 cursor-pointer text-center flex flex-row items-center justify-center xl:justify-center space-x-4 w-1/2 xl:w-1/5">
                    <FontAwesomeIcon icon={faArrowCircleLeft} className='mr-2' />Back to Fun
                </button>
    </div>*/}
            

            <iframe className='mx-auto w-full xl:w-2/3 overflow-auto' style={{border: "none", borderRadius: '10px', height: '50vh'}} src={gameData?.url}></iframe>

            {/*<div className="flex flex-row justify-center mt-8">
                <button onClick={() => navigate('/fun')} className="bg-primary text-black rounded py-2 cursor-pointer text-center flex flex-row items-center justify-center xl:justify-center space-x-4 w-1/2 xl:w-1/3">
                    <FontAwesomeIcon icon={faArrowCircleLeft} className='mr-2' />Back to Fun
                </button>
            </div>
            <div className='pt-16'>
                <FunBanner size={'1920x1080'} />
            </div>*/}
        </div>
    );
}

export default MiniGameComponent;
