import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
//import './blog.css';

const BlogPost = ({ post }) => {
  return (
    <article className="container mx-auto p-2 lg:p-4 lg:px-20 rounded shadow text-gray-200 bg-black-main">
      <div className='flex flex-row gap-3 items-center pl-2 py-2 lg:p-0 text-3xl lg:text-5xl uppercase mb-2'>
          <img
                    src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg"
                    alt="Avatar"
                    className={`w-16 h-auto rounded-full`}
                  /> 
            <div className='neon-flicker'>Andy's Based Blog</div>
        </div>
      <img src={post.headerImage} alt={post.title} className="w-full h-64 object-cover rounded mb-4" />
      <h1 className="text-4xl font-header text-white mb-4 uppercase text-center font-bold text-primary">{post.title}</h1>
      <span className="text-primary text-lg block text-center mb-4">{post.date}</span>
      <div>
        <div className="blog-post text-lg" dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
      <div className='w-full flex justify-center mt-10'>
        <Link to="/blog" className="mt-4 inline-block mx-auto px-4 py-2 rounded bg-primary text-inverted hover:bg-gray-700 hover:text-white"><FontAwesomeIcon icon={faBackward} className='mr-2' />Back to Blog Home</Link>
      </div>
    </article>
  );
};

export default BlogPost;
