import React from 'react';

const ToolsComponent = (id, dynamic_id) => (
  <div className="flex flex-col p-3 bg-black-main text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out" style={{ height: '59vh' }}>
    <div className='mx-auto text-pacifico text-2xl mb-4'>Andy Tools</div>
    <div>Placeholder for Andy Tools content.</div>
  </div>
);

export default ToolsComponent;
