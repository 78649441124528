import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBlogPostById } from '../api/userFunctions';
import BlogPost from '../components/blog/BlogPost';
import FunBanner from '../components/FunBanner';
import { Helmet } from 'react-helmet';
import config from '../config/config';

const Post = () => {
  const posts = useSelector((state) => state.blogs.items);
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(false); // Set to true for embedded blog***

  useEffect(() => {
    if (posts.blogPosts) {
      console.log('posts:', posts.blogPosts);
      const postsArray = Array.isArray(posts.blogPosts) ? posts.blogPosts : [posts.blogPosts];
      const matchingPost = postsArray.find((p) => p._id === id);
      setPost(matchingPost);
    }
  }, [posts, id]);

  /*useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await getBlogPostById(id);
        setPost(fetchedPost);
      } catch (error) {
        console.error('Error setting post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);*/

  if (!post) {
    return (<Helmet>
      <title>Andy's Based Blog - Crypto, Memecoins, and More!</title>
      <meta name="description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
      <meta name="keywords" content="ANDY memecoin, Andy's Place, crypto token, cryptocurrency, blockchain, AI characters, crypto merch, memecoin community, digital currency, crypto investment, Andy token, blockchain technology" />
      <link rel="icon" type="image/png" href="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
      <link rel="canonical" href={`${config.app_url}/blog`} />
      <meta property="og:title" content="Andy's Based Blog - Crypto, Memecoins, and More!" />
      <meta property="og:description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
      <meta property="og:image" content="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
      <meta property="og:url" content={`${config.app_url}/blog`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Andy's Based Blog" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Andy's Based Blog - Crypto, Memecoins, and More!"/>
      <meta name="twitter:description" content="Talking about ANDY token, memecoins, crypto, and that good stuff. It's pretty sweet, man." />
      <meta name="twitter:image" content="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/avatars/andyStoryGenerator.jpg" />
      <meta name="twitter:site" content={config.twitter} />
      <meta name="twitter:creator" content={config.twitter} />
    </Helmet>);
  }

  return (
    <div>
        <Helmet>
            <title>{post.title}</title>
            <meta name="description" content={post.excerpt} />
            <meta name="keywords" content="ANDY memecoin, Andy's Place, crypto token, cryptocurrency, blockchain, AI characters, crypto merch, memecoin community, digital currency, crypto investment, Andy token, blockchain technology" />
            <link rel="icon" type="image/png" href={post.headerImage} />
            <link rel="canonical" href={`${config.app_url}/blog/${post._id}`} />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.excerpt} />
            <meta property="og:image" content={post.headerImage} />
            <meta property="og:url" content={`${config.app_url}/blog/${post._id}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Andy's Based Blog" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.title}/>
            <meta name="twitter:description" content={post.excerpt} />
            <meta name="twitter:image" content={post.headerImage} />
            <meta name="twitter:site" content={config.twitter} />
            <meta name="twitter:creator" content={config.twitter} />
        </Helmet>
      <div className="container mx-auto p-4">
        {loading ? <p>Loading...</p> : post && <BlogPost post={post} />}
        <div className='pt-16'>
            <FunBanner size={'1920x1080'} />
        </div>
      </div>
    </div>
  );
};

export default Post;
