import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTool } from '../features/currentTool/currentToolSlice';
import { selectUser, setUser } from '../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { addToast } from '../features/ui/uiSlice';
import config from '../config/config';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';

const CardListFavoriteTools = ({ items, title }) => {
    const tools = useSelector((state) => [
        ...state.tools.items.tools,
        ...state.tools.items.assistants
    ].sort((a, b) => a.name.localeCompare(b.name)));
    const apiURL = config.apiUrl;
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    
    const findToolById = (toolId) => tools.find(tool => tool._id === toolId);

    // Filter items to only those that have a matching tool
    const validItems = items.filter(findToolById);

    const handleToolClick = (tool) => {
        dispatch(setCurrentTool(tool));
        navigate('/');
    };

    const removeFavorite = async (tool_id, type) => {
        try {
            await axios.post(`${apiURL}/favorites/remove`, {
                dynamic_id: user.dynamic_id,
                item_id: tool_id,
                type,
            });
            dispatch(addToast('Favorite Removed!', 'success'));
    
            const updatedFavorites = {
                ...user.favorites,
                [type]: user.favorites[type].filter(id => id !== tool_id),
            };
    
            const updatedUser = {
                ...user,
                favorites: updatedFavorites,
            };
            dispatch(setUser(updatedUser));
        } catch (error) {
            dispatch(addToast('Favorite NOT removed! Please try again.', 'error'));
        }
    };

    return (
        <div className="max-w-sm rounded max-h-96 overflow-auto slim-scrollbar shadow-lg p-5 border bg-primary text-black">
            <div className="font-bold text-xl mb-2">
                <FontAwesomeIcon className='text-inverted mr-2' icon={faStar} />{title}
            </div>
            <div className="mb-2 p-2 bg-primary text-inverted text-sm rounded">
                Click on a tool to open it in the chat space. Remove a favorite by clicking the star icon. Add a favorite by clicking the star icon in the tool card in the chat space.
            </div>
            {validItems.length === 0 ? (
                <p>No favorites have been selected.</p>
            ) : (
                <ul>
                    {validItems.map((itemId) => {
                        const tool = findToolById(itemId);
                        return (
                            <li key={itemId} className="flex items-center mb-4 cursor-pointer border rounded p-2 hover:bg-gray-300 hover:text-black">
                                <div onClick={() => handleToolClick(tool)} className="flex-grow flex items-center">
                                    <FontAwesomeIcon icon={faArrowRight} className='mr-2 ml-2' />
                                    <img className="w-12 h-12 rounded-full mr-4 border" src={tool.image_data.example_url} alt={tool.name} />
                                    <span className="flex-1">{tool.name}</span>
                                </div>
                                <button onClick={() => removeFavorite(tool._id, 'generators')} className="relative bottom-0 right-0 p-2">
                                    <FontAwesomeIcon icon={solidStar} className="text-yellow-500 hover:text-yellow-900 text-2xl"/>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default CardListFavoriteTools;
