// login.jsx
import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../features/user/userSlice';
import { authUser } from '../api/userFunctions';
import { StytchLogin } from '@stytch/react';
import { setSession, deleteSession } from '../features/session/sessionSlice';
import config from '../config/config';
import Cookies from 'js-cookie';
const sitename = config.siteName;
const stytch_login_url = config.stytch_login_url;


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const stytch_session = Cookies.get('stytch_session');
    const stytch_session_jwt = Cookies.get('stytch_session_jwt');
    
    const autoAuthenticate = async () => {
      if (stytch_session) {
        const user = await authUser(stytch_session);
        //console.log('user in login auto auth:', user);
        if (user?.success) {
          dispatch(setUser(user?.data));
          dispatch(setSession({ stytch_session_token: stytch_session }));
          navigate('/dashboard');
        } else {
          dispatch(setUser(null));
          dispatch(deleteSession());
          Cookies.remove('stytch_session');
          Cookies.remove('stytch_session_jwt');
          //navigate('/login');
        }
      }
    };

    autoAuthenticate();
  }, [dispatch, navigate]);

  const config = {
  "products": [
    "oauth",
    "emailMagicLinks"
  ],
  "oauthOptions": {
    "providers": [
      {
        "type": "google"
      }
    ],
    "loginRedirectURL": stytch_login_url,
    "signupRedirectURL": stytch_login_url
  },
  "emailMagicLinksOptions": {
    "loginRedirectURL": stytch_login_url,
    "loginExpirationMinutes": 30,
    "signupRedirectURL": stytch_login_url,
    "signupExpirationMinutes": 30,
    "loginTemplateId": "andys_place_login",
    "signupTemplateId": "andys_place_signup"
  }
};
  const styles = {
  "container": {
    "backgroundColor": "#121212",
    "borderColor": "#ADBCC5",
    "borderRadius": "8px",
    "width": "360px"
  },
  "colors": {
    "primary": "#fff",
    "secondary": "#5C727D",
    "success": "#0C5A56",
    "error": "#8B1214"
  },
  "buttons": {
    "primary": {
      "backgroundColor": "#fdc801",
      "textColor": "#121212",
      "borderColor": "#19303D",
      "borderRadius": "4px"
    },
    "secondary": {
      "backgroundColor": "#FFFFFF",
      "textColor": "#19303D",
      "borderColor": "#19303D",
      "borderRadius": "4px"
    }
  },
  "inputs": {
    "backgroundColor": "#FFFFFF00",
    "borderColor": "#19303D",
    "borderRadius": "4px",
    "placeholderColor": "#8296A1",
    "textColor": "#fff"
  },
  "fontFamily": "Arial",
  "hideHeaderText": false,
  "logo": {
    "logoImageUrl": "https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
  }
}

  return (<>
    <div className='w-full md:w-1/3 mx-auto px-5 flex flex-col justify-center items-center'>
      <span className='mt-3 text-pacifico text-primary text-2xl'>{sitename}</span>
        <h1 className="mt-3 text-center text-3xl text-pacifico mb-2">Login or Sign Up</h1>
        <a href='https://gaim.ai' alt='GAIM.AI' target='_blank' rel='noreferrer noopener' className="">
        <div className='flex flex-row items-center justify-center mb-3 mt-3 bg-black rounded-full p-2 px-4'>
          
          
                <img
                    src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                    alt="Avatar"
                    className={`w-6 h-6`}
                    /> 
                    <div className='text-center text-white ml-2' style={{color: '#99abd6'}}>Powered By GAIM.AI</div>
            
        </div>
        </a>
        <p className='rounded bg-blue-500 px-3 py-2 mb-3 text-white border-4 border-blue-500'>If you choose to log in or sign up via an email address, you will instantly receive an email that contains a link. Click this link in your email to complete the login or sign up process.</p>
        <StytchLogin config={config} styles={styles} />
        <p className='text-center mt-6 text-sm'>*We will never sell your data or spam you.</p>
    </div>
    
    
    
    
    </>);
}

export default Login;