import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCopy, faPaperPlane, faX } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from '../../utils/commons';

const DescriptionComponent = ({currentTool, addToast}) => {
  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
      addToast('Copied to clipboard!', 'success');
    } else {
      addToast('Failed to copy to clipboard!', 'error');
    }
  };

    return (
      <>
                  <div className="">
                  <div className='flex flex-row items-center justify-center w-full flex-wrap gap-2 overflow-auto slim-scrollbar mb-4'>
                    <div className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit' onClick={() => handleCopy('0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022')}>Contract<FontAwesomeIcon icon={faCopy} className='ml-2' /></div>
                    <a href='https://shop.andys.place' target='_blank' className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit'>Merch</a>
                    <a href='https://basescan.org/token/0x18a8bd1fe17a1bb9ffb39ecd83e9489cfd17a022' target='_blank' className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit'>Basescan</a>
                    <a href='https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840?t=1712845998905' target='_blank' className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit'><FontAwesomeIcon icon={faChartLine} /></a>
                    <a href='https://x.com/_AndyOnBase' target='_blank' className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit'><FontAwesomeIcon icon={faX} /></a>
                    <a href='https://t.me/AndyOnBasePortal' target='_blank' className='cursor-pointer px-2 py-1 border border-blue-500 hover:border-yellow-400 rounded w-fit'><FontAwesomeIcon icon={faPaperPlane} /></a>
                  </div>
                    <div className='flex flex-col w-full overflow-x-auto px-3'>
                      <div className='flex flex-col w-full overflow-x-auto gap-1 rounded bg-primary p-4'>
                        <img
                          src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
                          alt="Avatar"
                          className={`w-16 h-12 mx-auto`}
                        />
                        <div className='text-xl text-white text-pacifico mx-auto'>Who is Andy?</div>
                        <div>Along with best friends Pepe, Brett and Landwolf, Andy's iconic character featured in Matt Furie's now classic Boy's Club comic book series.</div>
                        <div>Andy is a lovable weirdo that spends his days enjoying drug-fueled hedonism and impish mischief. Always pranking, always laughing, he's one of the boys.</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-sm">
                    <div className='flex flex-col w-full overflow-x-auto pl-3'>
                      <div className="font-bold">Contract:</div>
                      <div>0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022</div>
                    </div>
                  </div>
                  <div className="text-sm px-3">
                    <div className="font-bold">About this tool:</div>
                    {currentTool?.description}
                  </div>
                </>
    );

}

export default DescriptionComponent;