import React, { useCallback, useState, useEffect } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import config from "../config/config";
import { getSelectedPackage } from "../features/packages/selectedPackageSlice";

const apiUrl = config.apiUrl;

const stripePromise = loadStripe("pk_live_51Mo9vdG2rariuf0Fkr6Mf17iBmftq63eaH9Ct7BuhIYmNBHtq3pLZCv4G9IJ95U6dua1bA39C0rmeh1lXgbrb2eu003V4DGWcT");
//pk_test_51Mo9vdG2rariuf0FfTILS1TfHEOKABhpyNRwl68r0gZhyz4QZ5Lxs2s2eSp09vQEYWYvqsJcPisirJfyGoZVKTds00N8MLHOeP
//pk_live_51Mo9vdG2rariuf0Fkr6Mf17iBmftq63eaH9Ct7BuhIYmNBHtq3pLZCv4G9IJ95U6dua1bA39C0rmeh1lXgbrb2eu003V4DGWcT
export const CheckoutForm = () => {
    const user = useSelector(selectUser);
    const packageItem = useSelector(getSelectedPackage) || {};
    
    const fetchClientSecret = useCallback(() => {
      if (!packageItem || !user || !packageItem.stripe_price_id || !user.dynamic_id) {
        console.warn('fetchClientSecret: Missing required data');
        return Promise.reject('Missing required data');
      }
    
      return fetch(`${apiUrl}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          price_id: packageItem.stripe_price_id, 
          quantity: 1, 
          dynamic_id: user.dynamic_id,
          return_url: `${window.location.origin}`,
          app_id: config.app_id
        })
      })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        if (data.clientSecret) {
          return data.clientSecret;
        }
        throw new Error('Client secret not found in response');
      });
    }, [packageItem, user, apiUrl]);  // Ensure dependencies are included here
    

  const options = {fetchClientSecret};

  return (
    <div id="checkout" className="mx-auto md:w-3/4 border border-4 border-blue-400">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${apiUrl}/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <div className="w-full md:w-3/4 mx-auto border border-4 border-green-400 rounded p-10 bg-black-main">
          <div className="flex flex-col text-center lg:flex-row items-center justify-center gap-3 lg:gap-10">
            <img
              src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AnimatedStickerRaining.gif"
              alt="Avatar"
              className={`w-24 h-24`}
            />
            <h1 className="text-5xl text-green-400 font-bold text-pacifico">Thank you for your purchase!</h1>
          </div>
          <div className="flex flex-col items-center justify-center mt-10 text-center text-white">
            <p>
              That's pretty cool, man! A confirmation email will be sent to {customerEmail}.
            </p>
            <p>
              If you have any questions, please email <a href="mailto:support@gaimnetwork.com">support@gaimnetwork.com</a>.
            </p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 rounded mt-10" onClick={() => navigate('/')}>Return to Andy's Place</button>
          </div>
          
        </div>
        
      </section>
    )
  }

  return null;
}