import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ToastComponent = ({ toasts }) => (
  toasts.length > 0 && (
    <div className="absolute bottom-20 right-4 bg-black max-w-72 p-1 rounded border border-primary" style={{ zIndex: '51' }}>
      {toasts.map(toast => (
        <div key={toast.id} className='flex items-center bg-black text-primary h-full w-full p-1 px-3'>
          <FontAwesomeIcon icon={faExclamationCircle} className='mr-2' />{toast.message}
        </div>
      ))}
    </div>
  )
);

export default ToastComponent;
