import React, {useRef} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import ScrollToTop from './components/ScrollToTop';
import Layout from './components/layout';
import Chart from './pages/Chart';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Fun from './pages/Fun';
import MiniBook from './pages/MiniBook';
import MiniGame from './pages/MiniGame';
import Home from './pages/Home';
import Login from './pages/Login';
import LoggingIn from './pages/LoggingIn';
import NFTChat from './pages/NFTChat';
import PackagePage from './pages/PackagePage';
import Settings from './pages/Settings';
import ShillShack from './pages/ShillShack';
import Shop from './pages/Shop';
import Workspace from './pages/Workspace';
import { CheckoutForm } from './components/StripeCheckout';
import { Return } from './components/StripeCheckout';
import ChatbotComponent from './components/ChatbotComponent';
import Blog from './pages/Blog';
import Post from './pages/Post';

const AppRouter = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      
      {/* Routes without Layout */}
      <Route path="/login" element={<Login />} />
      <Route path="/loggingin" element={<LoggingIn />} />

      {/* Routes with Layout */}
      
      <Route path="/" element={<Layout><RequireAuth><Home /></RequireAuth></Layout>} />
      <Route path="/chart" element={<Layout><RequireAuth><Chart /></RequireAuth></Layout>} />
      <Route path="/dashboard" element={<Layout><RequireAuth><Dashboard /></RequireAuth></Layout>} />
      <Route path="/nftchat" element={<Layout><RequireAuth><NFTChat /></RequireAuth></Layout>} />
      <Route path="/packages" element={<Layout><RequireAuth><PackagePage /></RequireAuth></Layout>} />
      <Route path="/profile" element={<Layout><RequireAuth><Profile /></RequireAuth></Layout>} />
      <Route path="/settings" element={<Layout><RequireAuth><Settings /></RequireAuth></Layout>} />
      <Route path="/shillshack" element={<Layout><RequireAuth><ShillShack /></RequireAuth></Layout>} />
      <Route path="/shop" element={<Layout><RequireAuth><Shop /></RequireAuth></Layout>} />
      <Route path="/workspace" element={<Layout><RequireAuth><Workspace /></RequireAuth></Layout>} />
      <Route path="/checkout" element={<Layout><RequireAuth><CheckoutForm /></RequireAuth></Layout>} />
      <Route path="/return" element={<Layout><RequireAuth><Return /></RequireAuth></Layout>} />

      <Route path="/fun" element={<Layout><Fun /></Layout>} />
      <Route path="/minibook" element={<Layout><MiniBook /></Layout>} />
      <Route path="/minigame" element={<Layout><MiniGame /></Layout>} />

      <Route path="/chatbot" element={<ChatbotComponent />} />
      <Route path="/blog" element={<Layout><Blog /></Layout>} />
      <Route path="/post/:id" element={<Layout><Post /></Layout>} />
    </Routes>
  </Router>
);

export default AppRouter;
