import React, { useEffect } from 'react';
import ChatbotChatComponent from './ChatbotChatComponent';


const ChatbotComponent = ({ dynamicId, toolId }) => {
    useEffect(() => {
      // Create a script element
      const script = document.createElement('script');
      script.src = 'https://gaim-embeds.nyc3.cdn.digitaloceanspaces.com/chatbot.js'; // Replace with the URL where chatbot.js is hosted
      script.async = true;
      script.onload = () => {
        // Initialize the chatbot after the script is loaded
        window.initializeChatbot();
      };
  
      // Append the script to the document body
      document.body.appendChild(script);
  
      // Clean up the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    return (
      <div className="w-fit">
        <ChatbotChatComponent dynamic_id={dynamicId} currentToolId={toolId} />
      </div>
    );
  };

export default ChatbotComponent;