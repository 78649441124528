import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComments, faImage, faAddressCard, faBolt, faLink, faHandPointUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import AvatarSelector from './AvatarSelector';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import { updateUserProfile, updateBYOKkey } from '../api/userFunctions';
import { addToast } from '../features/ui/uiSlice';
import CardListAddDelete from './CardListAddDelete';

const SettingsForm = () => {
    const user = useSelector(selectUser);
    const session = useSelector(selectSession);
    const { data, status, error } = useSelector((state) => state.BYOKUserAPIKeys);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const features = [
        { id: 'personalInfo', icon: faUser, title: 'Personal Information', description: 'Tell us about yourself' },
        /*{ id: 'integrations', icon: faComments, title: 'API Integrations', description: 'Manage your API keys' },
        { id: 'account', icon: faAddressCard, title: 'Account/Billing Information', description: 'Manage your subscription and billing information' },*/
    ];

    const [selectedFeature, setSelectedFeature] = useState(features[0].id);
    const [formData, setFormData] = useState({
        name: user.name,
        username: user.username,
        emailAddress: user.email,
    });
    const [isFormChanged, setIsFormChanged] = useState(false);

    useEffect(() => {
        setIsFormChanged(false);
    }, [selectedFeature]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setIsFormChanged(true);
    };

    const handleCancel = () => {
        // Reset form values to the original user data
        setFormData({
            name: user.name,
            username: user.username,
            emailAddress: user.email,
            // Add any other fields you need to reset
        });
    
        // Navigate to the /dashboard path
        navigate('/dashboard');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('user: ', user);
        const userWithKeys = {
            ...user,
            api_keys: user.api_keys || {}, // Provide a default empty object if `api_keys` doesn't exist
        };
        const payload = {
            ...userWithKeys,
            name: formData.name,
            username: formData.username,
            email: formData.emailAddress,
            api_keys: {
                openai: formData.openAIKey,
                stablediffusion: formData.stableDiffusionKey,
                leonardo: formData.leonardoAIKey,
                perplexity: formData.perplexity,
            },
        };
        const updateUser = await updateUserProfile(user.dynamic_id, payload, session);
        dispatch(addToast('Your profile has been updated!', 'success'));
        setIsFormChanged(false);
        dispatch(setUser(updateUser.data));
    };      

    const renderFormFields = () => {
        switch (selectedFeature) {
            case 'personalInfo':
                return (
                    <>
                    <div className="flex flex-col mb-4 w-full justify-center text-center items-center">
                        <div className="flex flex-col mb-4 w-full">
                            <AvatarSelector />
                        </div>
                        
                        
                            <div className="flex flex-col mb-4">
                                <label htmlFor="name" className="mb-2">Name</label>
                                <input type="text" id="name" name="name" placeholder="Name" value={formData.name} onChange={handleInputChange} className="p-2 border rounded-md text-black" />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label htmlFor="username" className="mb-2">Username</label>
                                <input type="text" id="username" name="username" placeholder="Username" value={formData.username} onChange={handleInputChange} className="p-2 border rounded-md text-black" />
                            </div>
                        
                        <div className="flex flex-col">
                            <label htmlFor="emailAddress" className="mb-2">Email Address</label>
                            <div>{formData.emailAddress}</div>
                        </div>
                        </div>
                    </>
                );
            /*case 'integrations':
                return (
                    <>
                        <div className="flex flex-col mb-4">
                            <CardListAddDelete items={data} title='Your API Keys' user={user} />
                        </div>
                        <div className="flex flex-col mb-4 p-4">
                            <p>Your API keys are securely stored and encrypted. You can add or remove API keys at any time.</p>
                            <p className='mt-4'>After saving an API key, for security purposes you will NOT be able to view it within this app.</p>
                        </div>
                    </>
                );
            case 'account':
                return (
                    <>
                        <div className="flex flex-col mb-4">
                            <label htmlFor="openAIKey" className="mb-2">Current Package</label>
                            <div className="mt-4">
                                <Link to="/settings" className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-900 focus:outline-none focus:ring">
                                    <FontAwesomeIcon icon={faBolt} className='mr-2' />BYOK Pro
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col mb-4">
                        </div>
                        <div className="flex flex-col mb-4">
                            <div className="mt-4">
                                <Link to="https://google.com" className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-900 focus:outline-none focus:ring">
                                    <FontAwesomeIcon icon={faHandPointUp} className='mr-2' />Upgrade Package
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col mb-4">
                        </div>
                        <div className="flex flex-col mb-4">
                            <div className="mt-4">
                                <Link to="https://google.com" className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-900 focus:outline-none focus:ring">
                                    <FontAwesomeIcon icon={faLink} className='mr-2' />Manage Stripe Subscription
                                </Link>
                            </div>
                        </div>
                    </>
                );*/
            // Add cases for other features if necessary...
            default:
                return null;
        }
    };
    

    return (
        <div className="w-full lg:w-10/12 mx-auto max-w-6xl inverted rounded p-3 lg:p-10">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-6 gap-x-8 gap-y-10">
                    <div className="col-span-6 lg:col-span-2 hidden lg:flex">
                        <div className="grid grid-cols-5">
                            {features.map((feature, index) => (
                                <div key={index} className="md:col-span-5 group relative flex items-left gap-x-6 rounded-lg p-3 text-sm leading-6 hover:bg-indigo-50 cursor-pointer" onClick={() => setSelectedFeature(feature.id)}>
                                    <div style={{ textAlign: 'center' }} className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-blue-500 group-hover:bg-white mx-auto md:mx-0">
                                        <FontAwesomeIcon icon={feature.icon} className="h-6 w-6 text-yellow-500 group-hover:text-indigo-600" />
                                    </div>
                                    <div className="flex-auto hidden md:block">
                                        <span className="block font-semibold text-inverted group-hover:text-indigo-600">
                                            {feature.title}
                                        </span>
                                        <p className="mt-1 text-inverted">{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-4">
                        <div className="mb-4 flex items-center justify-end gap-x-6">
                            <button type="button" className="text-sm font-semibold leading-6 text-inverted" onClick={handleCancel}>Cancel</button>
                            <button
                                type="submit"
                                disabled={!isFormChanged}
                                className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                                    isFormChanged ? 'bg-blue-600 hover:bg-blue-900' : 'bg-gray-300'
                                }`}
                                >
                                Save
                            </button>
                        </div>
                        <div className="mx-auto lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10 w-full">
                            {renderFormFields()}
                        </div>
                        
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SettingsForm;