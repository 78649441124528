import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const LinkRenderer = ({ href, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const MessageListComponent = ({ messages, result, messagesEndRef, showDescription }) => (
  <div className="w-full p-0 flex flex-col bg-primary overflow-auto text-black chat-return slim-scrollbar transition-height duration-300 ease-in-out" style={{ height: showDescription ? '0' : '59vh', padding: showDescription ? '0' : '8px' }} ref={messagesEndRef}>
    {messages.map((message) => (
      <div key={message.id} className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 ${message.isUser ? 'bg-white ml-8' : 'text-yellow-400 bg-gray-800'}`}>
        <div className='flex flex-col w-full'>
          <ReactMarkdown className="markdown-content" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={{ a: LinkRenderer }}>
            {message.text}
          </ReactMarkdown>
        </div>
      </div>
    ))}
    {result && (
      <div className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 text-yellow-400 bg-gray-800`}>
        <div className='flex flex-col w-full'>
          <ReactMarkdown className="markdown-content" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={{ a: LinkRenderer }}>
            {result}
          </ReactMarkdown>
        </div>
      </div>
    )}
  </div>
);

export default MessageListComponent;
