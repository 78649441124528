import React, { useState, useEffect, useRef } from 'react';
import config from '../config/config';
import { generateChatPublic, postMessagePublic } from '../api/aiFunctions';
import { getRecordByIdPublic, getUserByDynamicIDPublic, getUserByDynamicID, getRecordById } from '../api/userFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPaperPlane, faAngleDoubleDown, faChevronUp, faMessage } from '@fortawesome/free-solid-svg-icons';
import io from "socket.io-client";
import '../index.css';
//import '../styles/chatbot.module.css';
import { copyToClipboard } from '../utils/commons';
import DescriptionComponent from './chatbot/DescriptionComponent';
import ChatInputComponent from './chatbot/ChatInputComponent';
import MessageListComponent from './chatbot/MessageListComponent';
import ToastComponent from './chatbot/ToastComponent';
import HeaderComponent from './chatbot/HeaderComponent';
import MenuComponent from './chatbot/MenuComponent';
import AndyImagesComponent from './chatbot/ImagesComponent';
import AndyMerchComponent from './chatbot/MerchComponent';
import AndyGamesComponent from './chatbot/GamesComponent';
import AndyToolsComponent from './chatbot/ToolsComponent';

const ChatbotChatComponent = ({ dynamic_id, currentToolId }) => {
  const [user, setUser] = useState(null);
  const [currentTool, setCurrentTool] = useState(null);
  const [currentThreadId, setCurrentThreadId] = useState('');
  const [messages, setMessages] = useState([]);
  const [messagesToSubmit, setMessagesToSubmit] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [submittedMessages, setSubmittedMessages] = useState([]);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [result, setResult] = useState('');
  const messagesEndRef = useRef(null);
  const [isTalking, setIsTalking] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [showChatbot, setShowChatbot] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socketId, setSocketId] = useState('');
  const socketRef = useRef(null);
  const socketIdRef = useRef('');
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');

  useEffect(() => {
    const socketLocal = io.connect(config.apiPublicUrl);

    socketLocal.on('connect', () => {
      console.log('socketLocal connected:', socketLocal.id);
      setSocketId(socketLocal.id);
      socketIdRef.current = socketLocal.id;
    });

    socketLocal.on('connect_error', (error) => {
      console.log('socketLocal Connection Error Message:', error.message);
    });

    socketLocal.on("chatStream", messageListener);

    socketRef.current = socketLocal;

    return () => {
      socketLocal.off("chatStream", messageListener);
      socketLocal.disconnect();
    };
  }, []);

  const messageListener = (message) => {
    if (message.toolId && message.dynamic_id && message.socketId === socketIdRef.current) {
      let accumulator;

      if (message.chunkContent) {
        accumulator += message.chunkContent;
        setResult(prevResult => prevResult + message.chunkContent);
        scrollToBottom();

        if (message === '--complete--') {
          accumulator = '';
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let user, tool;
      //console.log('Fetching user and tool data...', dynamic_id, currentToolId);
      //console.log('config.apiUrl:', config.apiUrl);
      if (config.apiUrl === "http://localhost:8080") {
        user = await getUserByDynamicID(dynamic_id);
        tool = await getRecordById('Embed', currentToolId);
        setUser(user.data);
        setCurrentTool(tool.data);
        if (tool.data && tool.data.name) {
          setMessages([{ id: 1, text: tool.data.instructions ? tool.data.instructions : `Hey! I'm ${tool.data.name}. What's up?`, isUser: false }]);
        }
      } else {
        user = await getUserByDynamicIDPublic(dynamic_id, currentToolId);
        tool = await getRecordByIdPublic('Embed', currentToolId, dynamic_id);
        setUser(user);
        setCurrentTool(tool);
        if (tool && tool.name) {
          setMessages([{ id: 1, text: tool.instructions ? tool.instructions : `Hey! I'm ${tool.name}. What's up?`, isUser: false }]);
        }
      }
    };
    fetchData();
  }, [dynamic_id, currentToolId]);

  const addToast = (message, type) => {
    const id = Date.now();
    setToasts([...toasts, { id, message, type }]);
    setTimeout(() => {
      setToasts(toasts.filter(toast => toast.id !== id));
    }, 3000);
  };

  const toggleDescriptionExpand = () => {
    setShowDescription(!showDescription);
    setDescriptionExpanded(!descriptionExpanded);
  };

  const toggleShowChatbot = () => {
    setShowChatbot(!showChatbot);
  };

  const toggleMenuExpand = () => {
    setShowMenu(!showMenu);
  };

  const selectMenuItem = (item) => {
    setSelectedMenuItem(item);
    setShowMenu(false);
  };

  useEffect(() => {
    const toggleTalking = () => {
      setIsTalking(true);
      setTimeout(() => {
        setIsTalking(false);
      }, 1200);
    };
    toggleTalking();
    const intervalId = setInterval(toggleTalking, 7000);
    return () => clearInterval(intervalId);
  }, []);

  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
      addToast('Copied to clipboard!', 'success');
    } else {
      addToast('Failed to copy to clipboard!', 'error');
    }
  };

  const handleSend = async (text = inputValue) => {
    if (loading) return;
    setLoading(true);
    if (user.ai_credits < 1) {
      addToast('You need more AI credits to chat!', 'error');
      setLoading(false);
      return;
    }
    if (currentTool.type === 'assistant' && inputValue.trim() === '') {
      console.log('Please enter a message!')
      addToast('Please enter a message!', 'error');
      setLoading(false);
      return;
    }
    if (currentTool.type === 'assistant' && inputValue.trim() !== '') {
      setMessages(messages => [...messages, { id: messages.length + 1, text: inputValue, isUser: true }]);
      setInputValue('');
      setAwaitingResponse(true);
      const postAssistant = await postMessagePublic(user.dynamic_id, inputValue, currentThreadId, currentTool.oai_assistantId, socketIdRef.current, currentTool._id);
      setResult('');
      if (postAssistant) {
        setMessages(messages => [
          ...messages,
          {
            id: messages.length + 1,
            text: postAssistant.data,
            isUser: false,
            service: 'openai',
            messageCount: 1,
          }
        ]);
        if (postAssistant?.tokens) {
          user.ai_credits -= postAssistant.tokens;
        }
        setAwaitingResponse(false);
        setCurrentThreadId(postAssistant.threadId);
        scrollToBottom();
      }
    } else {
      if (inputValue?.trim() !== '') {
        const newMessage = { role: 'user', content: inputValue };
        setMessages(messages => [...messages, { id: messages.length + 1, text: inputValue, isUser: true }]);
        setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
        setInputValue('');
        setAwaitingResponse(true);
        setTimeout(() => {
          setIsTalking(true);
        }, 500);
      } else {
        addToast('Please enter a message!', 'error');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const checkScroll = () => {
      if (messagesEndRef.current) {
        const isAtBottom = messagesEndRef.current.scrollHeight - messagesEndRef.current.scrollTop === messagesEndRef.current.clientHeight;
        //setShowScrollButton(!isAtBottom);
      }
    };

    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener('scroll', checkScroll);
    }

    return () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [messagesEndRef.current]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (messagesToSubmit.length > submittedMessages.length && awaitingResponse) {
      const callgenerateChatPublic = async () => {
        console.log('socketId in component:', socketId)
        try {
          const lastNewMessage = messagesToSubmit[messagesToSubmit.length - 1];
          const chatResponse = await generateChatPublic(
            messagesToSubmit,
            lastNewMessage.content,
            'Best',
            currentTool._id,
            user.dynamic_id,
            socketIdRef.current
          );
          setMessages(messages => [
            ...messages,
            {
              id: messages.length + 1,
              text: chatResponse.data,
              isUser: false,
            }
          ]);
          setMessagesToSubmit(messagesToSubmit => [
            ...messagesToSubmit,
            { role: 'assistant', content: chatResponse.data }
          ]);
          setResult('');
          setUser(chatResponse?.user);
          setSubmittedMessages(submittedMessages => [...submittedMessages, lastNewMessage]);
          setIsTalking(false);
          setAwaitingResponse(false);
        } catch (error) {
          console.error('Failed to generate chat:', error);
          setAwaitingResponse(false);
        }
      };
      callgenerateChatPublic();
    }
  }, [messagesToSubmit, currentTool]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const handleSendMessage = () => {
    handleSend();
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'Andy Images':
        return <AndyImagesComponent id={currentToolId} dynamic_id={dynamic_id} />;
      case 'Andy Merch':
        return <AndyMerchComponent id={currentToolId} dynamic_id={dynamic_id} />;
      case 'Andy Games':
        return <AndyGamesComponent id={currentToolId} dynamic_id={dynamic_id} />;
      case 'Andy Tools':
        return <AndyToolsComponent id={currentToolId} dynamic_id={dynamic_id} />;
      case 'Back to Chat':
      default:
        return <MessageListComponent messages={messages} result={result} messagesEndRef={messagesEndRef} />;
    }
  };

  const renderChatInput = () => {
    if (selectedMenuItem === '' || selectedMenuItem === 'Back to Chat') {
      return (
        <ChatInputComponent
          inputValue={inputValue}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSend={handleSendMessage}
          toggleShowChatbot={toggleShowChatbot}
        />
      );
    } else {
      return (
        <div className="flex items-center justify-center bg-black-main border-t border-yellow-400 p-3">
          <button
            className="bg-blue-500 text-pacifico text-yellow-400 font-bold py-2 px-4 rounded hover:text-black hover:bg-yellow-400 focus:outline-none"
            onClick={() => selectMenuItem('Back to Chat')}
          >
            <FontAwesomeIcon icon={faMessage} className='mr-2' />Back to Chat
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <ToastComponent toasts={toasts} />
      {!showChatbot ? (
        <button className='fixed bottom-6 right-6 bg-primary text-black font-bold p-2 rounded-full hover:text-white hover:bg-blue-700 focus:outline-none' onClick={toggleShowChatbot}>
          <img
            src={config.logoUrl}
            alt="Avatar"
            className={`w-16 h-16 rounded-full ${isTalking ? 'talking' : ''}`}
          />
        </button>
      ) : (
        <div className="flex flex-col rounded border border-gray-600 z-50 absolute right-2 bottom-2 w-11/12 lg:w-1/3 xl:w-1/4" style={{ height: '75vh' }}>
          <div className="flex flex-col items-between h-full">
            <HeaderComponent
              currentTool={currentTool}
              isTalking={isTalking}
              toggleDescriptionExpand={toggleDescriptionExpand}
              showDescription={showDescription}
              toggleMenuExpand={toggleMenuExpand}
              showMenu={showMenu}
            />

            {/* Description section */}
            <div className={`flex flex-col gap-4 justify-between p-3 bg-black-main border-t border-yellow-400 text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out`} style={{ height: !descriptionExpanded ? '0' : '59vh', padding: !descriptionExpanded ? '0' : '8px' }}>
              {showDescription && <DescriptionComponent currentTool={currentTool} addToast={addToast} />}
              <button className="mt-2 text-yellow-400 hover:text-blue-500 focus:outline-none" onClick={toggleDescriptionExpand}>
                <FontAwesomeIcon icon={descriptionExpanded ? faChevronUp : faChevronDown} className='text-3xl' />
              </button>
            </div>

            {/* Menu section */}
            <div className={`flex flex-col gap-4 justify-between p-3 bg-black-main border-t border-yellow-400 text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out`} style={{ height: showMenu ? '59vh' : '0', padding: showMenu ? '8px' : '0' }}>
              {showMenu && <MenuComponent toggleMenuExpand={toggleMenuExpand} selectMenuItem={selectMenuItem} />}
            </div>

            {/* Main content section */}
            <div className={`flex flex-col gap-4 justify-between p-3 bg-black-main border-t border-yellow-400 text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out`} style={{ height: !descriptionExpanded && !showMenu ? '59vh' : '0', padding: !descriptionExpanded && !showMenu ? '8px' : '0' }}>
              {renderContent()}
            </div>

            {/* Bottom chat input area */}
            {renderChatInput()}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatbotChatComponent;
