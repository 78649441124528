import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

const ChatInputComponent = ({ inputValue, handleChange, handleKeyPress, handleSend, toggleShowChatbot }) => (
  <div className="pt-4 p-4 bg-gray-800" style={{ height: '8vh' }}>
    <div className="flex gap-2">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder="Type a message..."
        className="flex-grow p-2 rounded border-gray-300 text-black bg-gray-100"
      />
      <button onClick={handleSend} className="bg-primary text-black font-bold p-2 rounded hover:text-white hover:bg-yellow-500">
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
      <button onClick={toggleShowChatbot} className="bg-primary text-black font-bold p-2 px-4 rounded hover:text-white hover:bg-yellow-500">
        <FontAwesomeIcon icon={faAngleDoubleDown} />
      </button>
    </div>
  </div>
);

export default ChatInputComponent;
