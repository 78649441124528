import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faGamepad, faImages, faShop, faToolbox } from '@fortawesome/free-solid-svg-icons';

const MenuComponent = ({ toggleMenuExpand, selectMenuItem }) => (
  <div className="flex flex-col text-2xl p-3 bg-black-main text-yellow-400 overflow-auto slim-scrollbar transition-height duration-300 ease-in-out" style={{ height: '59vh' }}>
    <button className="mt-2 mb-6 text-white hover:text-yellow-400 focus:outline-none text-pacifico rounded bg-primary px-4 py-2" onClick={() => selectMenuItem('Back to Chat')}>
        <FontAwesomeIcon icon={faChevronLeft} className='mr-2' />Back to Chat
    </button>
    <button className="mt-2 text-yellow-400 hover:text-blue-500 focus:outline-none border border-yellow-400 hover:bg-yellow-400 text-pacifico rounded p-4" onClick={() => selectMenuItem('Andy Images')}>
        <FontAwesomeIcon icon={faImages} className='mr-4' />Andy Images
    </button>
    <button className="mt-2 text-yellow-400 hover:text-blue-500 focus:outline-none border border-yellow-400 hover:bg-yellow-400 text-pacifico rounded p-4" onClick={() => selectMenuItem('Andy Merch')}>
        <FontAwesomeIcon icon={faShop} className='mr-4' />Andy Merch
    </button>
    <button className="mt-2 text-yellow-400 hover:text-blue-500 focus:outline-none border border-yellow-400 hover:bg-yellow-400 text-pacifico rounded p-4" onClick={() => selectMenuItem('Andy Games')}>
        <FontAwesomeIcon icon={faGamepad} className='mr-4' />Andy Games
    </button>
    {/*<button className="mt-2 text-yellow-400 hover:text-blue-500 focus:outline-none border border-yellow-400 hover:bg-yellow-400 text-pacifico rounded p-4" onClick={() => selectMenuItem('Andy Tools')}>
        <FontAwesomeIcon icon={faToolbox} className='mr-4' />Andy Tools
</button>*/}
  </div>
);

export default MenuComponent;
