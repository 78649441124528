import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { selectMerchItems } from '../features/merch/merchSlice';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const getRandomItems = (items, maxItems) => {
    const shuffled = items.slice().sort(() => 0.5 - Math.random());
    return shuffled.slice(0, maxItems);
  };

function MerchSidebar() {
    const allMerchItems = useSelector(selectMerchItems);
    const [displayItems, setDisplayItems] = useState([]);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        let count = 0;
        const updateDisplayItems = () => {
            if (count < 5) {
                setIsVisible(false);
                setTimeout(() => {
                    const newItems = getRandomItems(allMerchItems, 15);
                    setDisplayItems(newItems);
                    setIsVisible(true);
                    count++;
                }, 30);
            }
        };
        updateDisplayItems();
        const intervalId = setInterval(() => {
            updateDisplayItems();
        }, 180000);
        return () => clearInterval(intervalId);
    }, [allMerchItems]);

  return (
    <div className={`w-full slim-scrollbar merch-display ${isVisible ? 'visible' : 'hidden'}`} style={{ overflowY: 'auto', maxHeight: '80vh' }}>
        <div className='flex flex-col justify-center items-center text-primary text-2xl font-bold text-pacifico mb-6'>
            <FontAwesomeIcon icon={faStore} className='mb-2 text-inverted' /><br></br>
            Andy Merch
        </div>
        {displayItems.map((item, index) => (
                <div key={index} className='mb-6 flex flex-col items-center text-center'>
                    {/* Image container */}
                    <Link to={`https://shop.andys.place/products/${item.product_name}`} target='_blank'>
                        <img src={item.image_url} alt={`Product ${item.name}`} className="w-40 h-40 object-cover mb-2 cursor-pointer rounded"/>
                    </Link>
                    <Link to={`https://shop.andys.place/products/${item.product_name}`} target='_blank'>
                        <span className='text-white hover:text-yellow-400 text-center'>{item.name}</span>
                    </Link>
                    <Link to={`https://shop.andys.place/products/${item.product_name}`} target='_blank' className="mt-2 bg-primary text-inverted py-2 px-4 rounded-lg font-semibold cursor-pointer hover:bg-opacity-90">
                        Shop Merch
                    </Link> 
                </div>
            ))}
    </div>
  );
}

export default MerchSidebar;