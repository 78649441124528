import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import config from '../config/config';
import { openModalIFrame } from '../features/modals/modaliframeSlice';
import { useNavigate } from 'react-router-dom';
import CardListAddDelete from '../components/CardListAddDelete';
import CardListFavoriteTools from '../components/CardListFavoriteTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faGear, faImages, faRobot, faStore } from '@fortawesome/free-solid-svg-icons';
import ChatbotComponent from '../components/ChatbotComponent';
//import TwitterFeed from '../components/TwitterFeed';

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
  
    return (
      <div className='flex flex-col items-center justify-center'>
        {user ? (<>
          <div className='flex flex-col lg:flex-row border rounded w-full lg:w-3/5 mb-6 p-6 items-center bg-primary text-black'>
            <div className='lg:w-1/5 flex justify-center mb-3 lg:mb-0'>
              <img src={user.pic} alt={user.name} className='w-24 h-24 rounded-full border-2 border-gray-900' />
            </div>
            <div className='flex flex-col w-full lg:w-3/5'>
              <div className='text-3xl mb-2'>Welcome, {user.name ? user.name : user.username}!</div>
              <div className='text-lg'>Email: {user.email}</div>
              <div className='text-lg'>Username: {user.username}</div>
              <div className='text-lg'>Credits: {Math.round(user?.ai_credits)}</div>
            </div>
            <div className='flex flex-col w-full lg:w-1/5'>
              <div className='text-lg text-black mt-2 lg:mt-0 lg:text-right'>It is currently:</div>
              <div className='text-lg lg:text-right'>{new Date().toLocaleString('en-US')}</div>
              <Link to="/settings" className='w-full'>
                <button className='w-full bg-primary hover:bg-blue-900 text-inverted hover:text-white font-bold py-2 px-4 rounded mt-4'><FontAwesomeIcon icon={faGear} className='mr-2' />Edit Profile</button>
              </Link>
            </div>
          </div>
          <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 w-full lg:w-3/5 lg:justify-center'>
            <Link to='/' className="mt-0 lg:m-0 w-full lg:w-fit border-4 border-blue-500 text-xl lg:text-3xl text-center lg:text-left px-5 py-3 lg:py-6 lg:px-24 bg-black-main text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
              <img
                  src="https://andyonbase.nyc3.cdn.digitaloceanspaces.com/AndyBaseAvatar.png"
                  alt="Avatar"
                  className={`w-16 h-auto mx-auto`}
                />
              Go to the Chat Space
            </Link>
          </div>
          <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 lg:w-3/5 lg:justify-center lg:gap-4'>
            <button className='lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{navigate('/packages');}}>         
                <FontAwesomeIcon icon={faRobot} className='mr-2' />Buy AI Credits
            </button>
            <Link to='https://shop.andys.place' target='_blank' className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faStore} className='mr-2' />Andy's Merch Shop
            </Link>
            <Link to='/shillshack' className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                <FontAwesomeIcon icon={faImages} className='mr-2' />Shill Shack
            </Link>
            <button className='mt-2 mb-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary text-inverted hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold' onClick={()=>{dispatch(openModalIFrame({ url:'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x18A8BD1fe17A1BB9FFB39eCD83E9489cfD17a022&swapAmount=' }));}}>         
                <FontAwesomeIcon icon={faDollarSign} className='mr-2' />Buy Andy on SushiSwap
            </button>
          </div>
          <div className='flex flex-row flex-wrap gap-5'>
              <div className="">
                <CardListFavoriteTools items={user.favorites?.generators} title='Your Favorite Tools' />
              </div>
              {/*<div className="">
                <CardListAddDelete items={data} title='Your API Keys' user={user} />
              </div>*/}
          </div>
          
        </>) : (
          <p>No user is logged in.</p>
        )}
        {/*<ChatbotComponent dynamicId={user.dynamic_id} toolId={'665d235d72ea17168666b91d'}/>*/}
      </div>
    );
  }

export default Dashboard;
