// src/app/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // This defaults to localStorage for web applications
import bannerReducer from '../features/games/bannersSlice';
import blogReducer from '../features/tools/blogSlice';
import BYOKUserAPIKeysReducer from '../features/byokUserAPIKeys/BYOKUserAPIKeysSlice';
import chatReducer from '../features/chat/chatSlice';
import currentNFTChatReducer from '../features/currentTool/currentNFTChatSlice';
import currentToolReducer from '../features/currentTool/currentToolSlice';
import menuReducer from '../features/mobile/menuSlice';
import merchItemsReducer from '../features/merch/merchSlice';
import miniGamesReducer from '../features/games/gamesSlice';
import modalIFrameReducer from '../features/modals/modaliframeSlice';
import modalNewsletterReducer from '../features/games/modalNewsletterSlice';
import modalPackagesReducer from '../features/packages/modalPackagesSlice';
import packageReducer from '../features/packages/packageSlice';
import packageItemReducer from '../features/packages/packageItemSlice';
import selectedPackageReducer from '../features/packages/selectedPackageSlice';
import selectedProfileReducer from '../features/user/selectedProfileSlice';
import servicesReducer from '../features/services/servicesSlice';
import sessionReducer from '../features/session/sessionSlice';
import shillImageSlice from '../features/shillImages/shillImageSlice';
import threadReducer from '../features/threads/threadSlice';
import toolReducer from '../features/tools/toolSlice';
import toolsModalReducer from '../features/tools/toolsModalSlice';
import uiReducer from '../features/ui/uiSlice';
import userNFTsReducer from '../features/user/userNFTsSlice';
import userReducer from '../features/user/userSlice';
import userPromptsReducer from '../features/userPrompts/userPromptsSlice';

// Persist configuration


const rootReducer = combineReducers({
  banners: bannerReducer,
  blogs: blogReducer,
  BYOKUserAPIKeys: BYOKUserAPIKeysReducer,
  chat: chatReducer,
  currentNFTChat: currentNFTChatReducer,
  currentTool: currentToolReducer,
  menu: menuReducer,
  merchItems: merchItemsReducer,
  miniGames: miniGamesReducer,
  modalIFrame: modalIFrameReducer,
  modalNewsletter: modalNewsletterReducer,
  modalPackages: modalPackagesReducer,
  packages: packageReducer,
  packageItems: packageItemReducer,
  selectedPackage: selectedPackageReducer,
  selectedProfile: selectedProfileReducer,
  services: servicesReducer,
  session: sessionReducer,
  shillImages: shillImageSlice,
  thread: threadReducer,
  tools: toolReducer,
  toolsModal: toolsModalReducer,
  ui: uiReducer,
  user: userReducer,
  userNFTs: userNFTsReducer,
  userPrompts: userPromptsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // You can add whitelist or blacklist here to choose which reducers to persist
  blacklist: ['menu', 'toolsModal', 'ui', 'modalIFrame', 'modalPackages', 'thread'],
};

// Enhanced reducer with persistence capabilities
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store as usual, but replace the root reducer with the persistedReducer
export const store = configureStore({
  reducer: persistedReducer,
  // Add any middleware or enhancers here
});

// Create a persistor for your store
export const persistor = persistStore(store);

store.subscribe(() => {
  const state = store.getState();
  const servicesData = state.services.data;
  localStorage.setItem('servicesData', JSON.stringify(servicesData));
});