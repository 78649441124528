import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../features/ui/uiSlice';
import { setUser } from '../features/user/userSlice';
import { setPackageItem } from '../features/packages/packageItemSlice';
import { setPackage } from "../features/packages/selectedPackageSlice";
import config from "../config/config";
import { useUser, useSDK, useLogin, useConnect, Web3Button } from "@thirdweb-dev/react";
import axios from "axios";
import BigNumber from "bignumber.js";
import { useNavigate } from 'react-router-dom';
import { useIsMobileOrTablet, useIsLaptopOrDesktop } from '../utils/useScreenSize';
import { useActiveWallet } from "thirdweb/react";
import ConnectButtonComponent from './ConnectButton';
import ConnectButtonMobileComponent from './ConnectButtonMobile';
import { selectSession } from '../features/session/sessionSlice';

//const client = createThirdwebClient({ clientId: '841cd7c05f6572ec017b34f5ce3b0920'});

const apiUrl = config.apiUrl;

const PurchaseCreditsComponent = ({ packageItem }) => {
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const navigate = useNavigate();

const handleSelectPackage = () => {
  alert('Selected package: ' + packageItem);
  dispatch(setPackageItem(packageItem));
  //navigate('/checkout');
};

  if (!packageItem) {
    return <div>Loading packages...</div>;
}

  return (<>
    <div className="flex flex-row items-center justify-center gap-4">
    {!session ? (<>Login to Purchase
        </>) : (<>
          <button onClick={()=> {dispatch(setPackage(packageItem)); navigate('/checkout');}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        
        
        <div className="flex items-center">
              {session ? (<>
                Purchase for<br />${packageItem.usd_cost} USD
                </>) : (
                <>
                Login to Purchase
                </>
                )}
            </div>
        </button>
        </>)}

      



    </div>
  </>);
}

export default PurchaseCreditsComponent;